import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import AppRouter from './App';
import './index.css';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<AppRouter />
		</BrowserRouter>
	</React.StrictMode>,
	 document.body.children[2]
);
